<template>
  <material-dialog v-model="dialog" title="匯出單位領域版本" max-width="500">
    <material-select 
      v-model="nowYear"
      prependTitle="選擇年度" 
      label="選擇年度" 
      :items="versionList" 
      item-text="year" 
      item-value="year"
      @change="selectYear"
      ></material-select>
    <material-select
      :value="nowVersion"
      @input="nowVersionHandler"
      :items="noList"
      prependTitle="選擇版本"
      label="選擇版本"
      :disabled="yearNoData === null || Object.keys(yearNoData).length === 0"
    >
    </material-select>
    <material-select
      v-model="groupSelected"
      prependTitle="單位別" 
      label="選擇單位" 
      class="mb-5"
      :items="groupList"
      item-value="name"
      item-text="name"
      >
      <template #append-outer>
        <v-btn @click="selectVersion" outlined color="primary"> 選擇 </v-btn>
      </template>
    </material-select>
    <p>
      已選擇 <span class="primary--text"> {{ selected.length }}</span> 個版本
    </p>
    <chip-grey v-for="(i, idx) of selected" :key="idx" class="mr-1">
      {{ i.year }}-{{ versionText(i.no) }}-{{ i.unit }}
    </chip-grey>
    <template #actions>
      <v-spacer></v-spacer>
      <btn-cancel @click="closeDialog">取消</btn-cancel>
      <v-btn color="primary" depressed @click="closeDialog"> 匯出 </v-btn>
    </template>
  </material-dialog>
</template>
<script>
import {apiUnitFieldExport} from '../../../api/unit-field'
import {
  apiGetVerions,
  apiGetRiskType
} from '../../../api/risk-type'
import { apiGetGroups } from '../../../api/settings'
import { versionMixin } from '../../../helper'

export default {
  props: {
    dialog: {
      type: Boolean,
    },
  },
  mixins: [versionMixin],
  data() {
    return {
      versionList: [],
      nowVersion: "",
      selected: [],
      yearNoData: {},
      groupList: [],
      nowYear: null,
      groupSelected: null,
      noList: [], // 版本列表
    };
  },
  async mounted(){
    await this.defaultYearList()
    await this.defaultGroups()
  },
  methods: {
    nowVersionHandler(val) {
      this.nowVersion = val;
    },   
    closeDialog() {
      this.$emit("close");
      this.selected = [];
      this.nowVersion = "";
      this.groupSelected = null
      this.nowYear = null
    },
    selectVersion() {
      console.log('select version')
      if (!this.nowVersion) return;
      if (this.selected.findIndex((el) => el == this.nowVersion) != -1) return;
      let valueExist = this.checkRepeatVersion()
      if(valueExist == false){
        this.selected.push({
          year: this.nowYear, 
          no: this.versionText(this.nowVersion),
          unit: this.groupSelected
        });
      }      
      console.log(this.selected)
    },
    // 檢查匯出選擇是否有重複的單位領域版本
    checkRepeatVersion(){
      let checkRepeat = false
      this.selected.forEach((el) => {
        console.log(el)
        if(el.year == this.nowYear && el.no == this.versionText(this.nowVersion) && el.unit == this.groupSelected){
          checkRepeat = true
        }
      })
      return checkRepeat
    },
    selectYear(){
      this.noList = this.yearNoData[this.nowYear]
    },
    // 取得所有群組
    async defaultGroups(){
      try{
        let res = await apiGetGroups();
        this.groupList = res.data
      }catch(error){
        console.log(error)
      }
    },
    // 取得所有年度並過濾重複部份
    async defaultYearList(){
      try{
        let res = await apiGetVerions();
        this.versionList = res.data
        let uniqueYear = new Set()
        let uniqueList = res.data.filter(element => {
          const isDuplicate = uniqueYear.has(element.year)
          uniqueYear.add(element.year)

          if (!isDuplicate){ return true}
          return false
        })
        this.versionList = uniqueList           
        this.defaultNoList()
      }catch(error){
        console.log(error)
      }
    },
    // 組成對應年度的版本列表Object
    async defaultNoList(){
      try{
        let res = await apiGetRiskType({page:1 , size: 999})
        console.log(res)
        for(let item of res.data.content){
            console.log(item)       
            if(this.yearNoData[item.year] == undefined){
              this.yearNoData[item.year] = [item.no]
            }
            else{
              this.yearNoData[item.year].push(item.no)
            }
        }
        console.log(this.yearNoData)
      } catch(error){
        console.log(error) 
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
