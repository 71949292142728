<template>
  <v-container fluid>
    <div class="d-flex justify-space-between mb-5">
      <div class="d-flex align-baseline">
        <h1 class="text-h1">單位稽核領域</h1>
        <v-btn text @click="isExpand = !isExpand" class="ml-2"
          ><v-icon>mdi-filter-variant</v-icon><span class="ml-1">篩選</span>
        </v-btn>
      </div>
      <div class="d-flex align-baseline">
        <!-- <v-switch
          v-model="cancelHandler"
          color="error"
          class="ma-0"
          hide-details
        >
          <template #label>
            <div class="text-no-wrap">取消版本</div>
          </template>
        </v-switch> -->
        <v-btn
          @click="exportUnitFieldDialog = true"
          depressed
          color="primary"
          class="ml-2"
          >匯出</v-btn
        >
        <v-btn
          v-if="rolesCheck('unitFieldAdd')"
          @click="beforeAdd"
          depressed
          color="primary"
          class="ml-2"
          >新增單位版本</v-btn
        >
      </div>
    </div>
    <!-- <div class="d-flex">
      <div class="mr-5"> -->
    <material-filter
      v-if="isExpand"
      :showUnit="true"
      :isExpand="isExpand"
      @filter="filterChoose"
    ></material-filter>
    <!-- </div>
      <div style="width: 100%"> -->
    <material-table
      :headers="headers"
      :items="tableData"
      :options.sync="options"
    >
      <template #no="{ item }">
        {{ versionText(item.no) }}
      </template>
      <template #groupId="{ item }">
        {{ groupTrans[item.groupId] }}
      </template>
      <template #status="{ item }">
        <chip-status small :status="item.status"></chip-status>
      </template>
      <template #updatedAt="{ item }">
        {{ dateFormate(item.updatedAt) }}
      </template>
      <template #versioningAt="{ item }">
        {{ dateFormate(item.versioningAt) }}
      </template>
      <template #createdAt="{ item }">
        {{ dateFormate(item.createdAt) }}
      </template>
      <template #actions="{ item }">
        <div class="d-flex">
          <v-icon @click="goDetail(item)" class="mr-3 pointer">mdi-eye</v-icon>
          <v-icon
            v-if="rolesCheck('unitFieldDelete')"
            @click="deleteVersion(item)"
            class="pointer"
            >mdi-delete</v-icon
          >
          <!-- <div @click="cancelVersion(item)" v-show="cancelHandler">
            <v-btn text color="error">取消</v-btn>
          </div> -->
        </div>
      </template>
    </material-table>
    <material-pagination
      :totalPages="totalPages"
      @pageFilter="pageFilter"
    ></material-pagination>
    <!-- :data-class="'noTrColor'" -->
    <!-- </div> -->
    <!-- </div> -->

    <!-- Dialogs -->
    <!-- 新增版本 -->
    <material-dialog
      v-if="addPublicDialog"
      v-model="addPublicDialog"
      title="新增單位領域版本"
      max-width="500"
    >
      <v-select
        v-model="version"
        label="選擇風險類型與項目版本"
        :items="versionList"
        outlined
        class="material-select mb-3"
        hide-details
        single-line
        dense
        :menu-props="{ offsetY: true }"
      >
        <template #prepend>
          <div class="text-no-wrap mr-2">引用版本</div>
        </template>
        <template slot="selection" slot-scope="data">
          {{ data.item.year }}-{{ versionText(data.item.no) }}
        </template>
        <template slot="item" slot-scope="data">
          {{ data.item.year }}-{{ versionText(data.item.no) }}
        </template>
      </v-select>

      <v-autocomplete
        v-model="groupSelected"
        label="選擇單位"
        class="material-select mb-3"
        :items="groupList"
        item-text="name"
        item-value="id"
        outlined
        hide-details
        single-line
        dense
        :menu-props="{ offsetY: true }"
        multiple
      >
        <template #prepend>
          <div class="text-no-wrap mr-2">單位別</div>
        </template>
        <template v-slot:selection="{ item, index }">
          <span v-if="index === 0">{{ item.name }}</span>
          <span v-if="index === 1" class="grey--text text-caption">
            (總共: {{ groupSelected.length }} 個版本 )
          </span>
        </template>
        <template v-slot:prepend-item>
          <v-list-item ripple @mousedown.prevent @click="selectAll">
            <v-list-item-action>
              <v-icon
                :color="groupSelected.length > 0 ? 'indigo darken-4' : ''"
              >
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <span style="font-size: 15px">全選</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-autocomplete>
      <material-select
        v-model="executeYear"
        prependTitle="執行年度"
        label="選擇年度"
        class="mb-5"
        :items="executeYearList"
      ></material-select>
      <p>
        新增風險類型與項目版本號<chip-grey v-if="version != null" class="ml-2"
          >{{ version.year }}-{{ versionText(version.no) }}</chip-grey
        >
        <br />
      </p>
      共建立
      <span class="primary--text">{{ groupSelected.length }}</span>
      個單位領域版本
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn @click="addPublicDialog = false" depressed>取消</v-btn>
        <v-btn @click="addUnitField" depressed color="primary">新增</v-btn>
      </template>
    </material-dialog>
    <!-- 匯出版本 -->
    <export-unit-field-dialog
      v-if="exportUnitFieldDialog"
      :dialog="exportUnitFieldDialog"
      @close="exportUnitFieldDialog = false"
    ></export-unit-field-dialog>
    <!-- 刪除版本 -->
    <material-dialog
      title="刪除單位領域版本"
      v-model="deleteAlert"
      max-width="500"
    >
      您確定要刪除單位版本
      <chip-grey>
        <!-- TODO: -->
        {{ `${nowItem.year}-${nowItem.no}` }}
      </chip-grey>
      嗎？
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="deleteUnitField" depressed>刪除版本</v-btn>
        <btn-cancel @click="deleteAlert = false">保留版本</btn-cancel>
      </template>
    </material-dialog>
    <!-- 取消版本 -->
    <material-dialog
      title="取消單位領域版本"
      v-model="cancelAlert"
      max-width="500"
    >
      <material-select
        prependTitle="取消版本號"
        label="選擇版本"
        class="mb-5"
      ></material-select>
      您確定要取消嗎？
      <alert-card
        >狀態更改為取消後，引用此版本之風險評估、稽核計畫相關版本將自動變更狀態為取消，並不可進行編輯</alert-card
      >
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="cancelAlert = false" depressed
          >取消版本</v-btn
        >
        <btn-cancel @click="cancelAlert = false">保留版本</btn-cancel>
      </template>
    </material-dialog>
  </v-container>
</template>
<script>
import AlertCard from "./components/AlertCard.vue";
import MaterialPagination from "../../components/MaterialPagination.vue";
import ExportUnitFieldDialog from "./components/ExportUnitFieldDialog.vue";
import { dateFormateMixin, versionMixin, checkRolesMixin } from "../../helper";
import { apiGetGroups } from "../../api/settings";
import { apiGetRiskType } from "../../api/risk-type";
import {
  apiGetUnitField,
  apiAddUnitField,
  apiDeleteUnitField,
} from "../../api/unit-field";
export default {
  components: { AlertCard, MaterialPagination, ExportUnitFieldDialog },
  name: "AuditFieldTable",
  mixins: [dateFormateMixin, versionMixin, checkRolesMixin],
  data() {
    return {
      headers: [
        {
          text: "年度",
          align: "start",
          sortable: false,
          value: "year",
        },
        { text: "版本", value: "no", sortable: false },
        { text: "單位", value: "groupId", sortable: false },
        { text: "狀態", value: "status", sortable: false },
        { text: "更新時間", value: "updatedAt" },
        { text: "定版時間", value: "versioningAt" },
        { text: "建立時間", value: "createdAt" },
        { text: "操作", value: "actions", sortable: false, width: 100 },
      ],
      tableData: [
        // {
        //   id: 1,
        //   year: "2022",
        //   no: "02",
        //   status: 0,
        //   unit: "車險商品部",
        //   updatedAt: new Date(),
        //   versioningAt: new Date(),
        //   createdAt: new Date(),
        // },
      ],

      options: {
        sortBy: ["createdAt"],
      },
      tab: 0,
      // cancelHandler: false,
      nowItem: {},
      //pagination
      page: 1,
      size: 10,
      //dialogs
      addPublicDialog: false,
      exportUnitFieldDialog: false,
      deleteAlert: false,
      cancelAlert: false,
      //filter
      isExpand: false,
      groupTrans: {},
      page: 1,
      itemsPerPage: 10,
      filterData: null,
      totalPages: 1,
      // 新增單位版本參數
      groupSelected: [],
      groupList: [],
      versionSelected: null,
      versionList: [],
      version: null,
      nowYear: new Date(),
      executeYearList: [],
      executeYear: null,
    };
  },
  computed: {
    chooseAllGroup() {
      return this.groupSelected.length === this.groupList.length;
    },
    chooseSomeGroup() {
      return this.groupSelected.length > 0;
    },
    icon() {
      if (this.chooseAllGroup) return "mdi-close-box";
      if (this.chooseSomeGroup) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    options: {
      handler() {
        this.unitFieldSearch();
      },
    },
  },
  async mounted() {
    await this.getGroups();
    await this.unitFieldSearch();
  },
  methods: {
    async unitFieldSearch() {
      try {
        let payload = this.makeUrlData();
        console.log(payload);
        let res = await apiGetUnitField(payload);
        console.log({ res });
        this.tableData = res.data.content;
        this.totalPages = res.data.totalPages;
      } catch (err) {
        console.log({ err });
      }
    },
    goDetail(item) {
      this.$router.push(`/audit-field/unit-field/${item.id}`);
    },
    deleteVersion(item) {
      this.nowItem = item;
      this.deleteAlert = true;
    },
    cancelVersion(item) {
      this.nowItem = item;
      this.cancelAlert = true;
    },
    async deleteUnitField() {
      try {
        let res = await apiDeleteUnitField(this.nowItem.id);
        console.log(res);
        this.deleteAlert = false;
        this.unitFieldSearch();
      } catch (err) {
        console.log({ err });
      }
    },
    async beforeAdd() {
      try {
        let addVersionParams = {
          page: 1,
          size: 999,
          status: 0,
          sortBy: "createdAt",
          order: "desc",
        };
        let res = await apiGetRiskType(addVersionParams);
        this.executeYearList = [
          this.nowYear.getFullYear(),
          this.nowYear.getFullYear() + 1,
        ];
        this.versionList = res.data.content;
        console.log(this.versionList);
      } catch (error) {
        console.log(error);
      }
      this.addPublicDialog = true;
    },
    async addUnitField() {
      try {
        let addData = {
          riskTypeId: this.version.id,
          groupId: this.groupSelected,
          year: this.version.year,
          no: this.version.no,
          executeYear: this.executeYear,
        };
        let res = await apiAddUnitField(addData);
        this.addPublicDialog = false;
        this.unitFieldSearch();
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    // 分頁搜尋
    pageFilter() {
      let pageParams = arguments[0];
      console.log(arguments[0]);
      this.page = pageParams.page;
      this.itemsPerPage = pageParams.itemsPerPage;
      this.unitFieldSearch();
    },
    // 過濾元件
    filterChoose() {
      this.filterData = arguments[0];
      console.log(this.filterData);
      this.unitFieldSearch();
    },
    // 取得所有單位資料
    async getGroups() {
      console.log("group");
      try {
        let res = await apiGetGroups();
        console.log({ res });

        let data = res.data;
        data.forEach((el) => {
          // TODO: 對照group list 找到 group name
          this.groupTrans[el.id] = el.name;
        });
        this.groupList = res.data;
      } catch (err) {
        console.log({ err });
      }
    },

    // 單位別選擇全部
    selectAll() {
      this.$nextTick(() => {
        if (this.chooseAllGroup) {
          this.groupSelected = [];
        } else {
          this.groupSelected = this.groupList.slice();
        }
      });
    },
    // 建立過濾網址參數
    makeUrlData() {
      let searchData = {};
      // searchData["page"] = this.options.page;
      searchData["page"] = this.page;
      searchData["size"] = this.itemsPerPage;
      searchData["sortBy"] = [null, undefined, "", "updatedAt"].includes(
        this.options.sortBy[0]
      )
        ? "updatedAt"
        : this.options.sortBy[0];
      searchData["order"] = this.options.sortDesc[0] == true ? "asc" : "desc";

      if (this.filterData != null) {
        // 加入 filterData status
        if (![undefined, null, ""].includes(this.filterData.status)) {
          searchData["status"] = this.filterData.status;
        }
        // 加入 start
        if (![undefined, null, ""].includes(this.filterData.start)) {
          searchData["start"] = this.filterData.start;
        }
        // 加入 end
        if (![undefined, null, ""].includes(this.filterData.end)) {
          searchData["end"] = this.filterData.end;
        }
        // 加入 groups
        if (![undefined, null, ""].includes(this.filterData.groups)) {
          searchData["groups"] = this.filterData.groups;
        }
      }
      return searchData;
    },
  },
};
</script>
<style scoped lang="scss">
.material-select {
  width: 60%;
  &:deep(.v-input__control) {
    min-width: 200px;
    background: #fff;
  }
  &:deep(.v-input__prepend-outer) {
    color: #222;
    div {
      min-width: 65px;
    }
  }
}
</style>
